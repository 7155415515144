import React, { useState, useEffect } from "react";
import "./Div.css";
import star from "../../Asists/img/06.png";
import file from "../../Asists/img/domain.png";
import PhoneInput from "react-phone-input-2";
import foto_absalute from "../../Asists/img/foto.png";
import fintech from "../../Asists/img/fintechhub.svg";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

export default function Div() {
  const [num, setNum] = useState("");
  const [name, setName] = useState("");
  const [utmSource, setUtmSource] = useState("default");
  const [utmContent, setUtmContent] = useState("default");
  const [utmMedium, setUtmMedium] = useState("default");
  const [utmId, setUtmId] = useState("default");
  const navigate = useNavigate();
  const location = useLocation();

  const utm_src_url = `source:${utmSource} content:${utmContent} id:${utmId} medium:${utmMedium}`;

  useEffect(() => {
    // URL'dan UTM source ni olish
    const searchParams = new URLSearchParams(window.location.search);
    const source = searchParams.get("utm_source") || "default";
    const content = searchParams.get("utm_content") || "default";
    const medium = searchParams.get("utm_medium") || "default";
    const id = searchParams.get("utm_id") || "default";
    setUtmSource(source);
    setUtmContent(content);
    setUtmMedium(medium)
    setUtmId(id);
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    const data = {
      familiya: utm_src_url,
      ism: name,
      kurs: "frontend",
      phone: num,
      target: 4,
      desc: location.search,
    };

    try {
      await axios
        .post("https://form-api.fintechhub.uz/api/register/", data)
        .then((response) => {
          // Forma maydonlarini tozalash va "thank you" sahifasiga yo'naltirish
          setName("");
          setNum("");
          navigate("/thankyou");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTelegramClick = async () => {
    const telegramData = {
      familiya: "telegram",
      ism: "Telegram orqali bog'lanish",
      kurs: "frontend",
      phone: "+123456789", // Dinamik telefon raqamini o'zgartiring
      target: 4,
      desc: utm_src_url, // UTM linkni `desc` sifatida yuboramiz
    };

    try {
      await axios
        .post("https://form-api.fintechhub.uz/api/register/", telegramData)
        .then((response) => {
          // Keyingi harakatlar
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }

    // Tashqi havolaga o'tish uchun
    window.location.href = "https://t.me/Fintech_Sotuv";
  };

  return (
    <div>
      <div className="boss">
        <div className="first">
          <img className="fintech" src={fintech} alt="" />
          <div className="box-top-left">
            <i className="fa-solid fa-graduation-cap"></i>
            <h1 className="text-top-left">
              Dasturlashni <br />
              o'rganmoqchimisiz?
            </h1>
          </div>
          <div className="center-left">
            <h1 className="center-text">Unda bizni eshiting:</h1>
            <div className="list">
              <p className="left-text">
                <img src={star} alt="" />
                Senior darajadagi ustozlar
              </p>
              <p className="left-text">
                <img src={star} alt="" />
                Amaliy ko'nikmalar
              </p>
              <p className="left-text">
                <img src={star} alt="" />
                Pullik Real loyihalarda tajriba to'plash
              </p>
              <p className="left-text">
                <img src={star} alt="" />
                Startup loyihangizga investitsiya kiritish imkoniyati
              </p>
              <p className="left-text">
                <img src={star} alt="" />
                Zamonaviy dasturlash atmosferasi
              </p>
            </div>
          </div>
          <div className="left-bottom">
            <img src={file} alt="" />
            <p className="bottom-p">
              Professional dasturchi bo'lmoqchi bo'lsangiz hoziroq
              <br /> ro‘yxatdan o‘ting!
            </p>
          </div>
        </div>
        <div className="box">
          <p className="location">
            <i className="fa-solid fa-location-dot"></i>
            Toshkent
          </p>
          <div className="second">
            <h1 className="right-top">
              Dasturlashga ilk qadam <br />
              <span>FinTechHub</span>
              dan boshlanadi!
            </h1>
            <p className="right">
              Bepul maslahat olish uchun ro'yhatdan o'ting
            </p>
            <form onSubmit={handleRegister}>
              <input
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Ismingiz"
                value={name}
                required
              />
              <div className="phone">
                <PhoneInput
                  country={"uz"}
                  value={num}
                  onChange={(phone) => setNum(phone)}
                />
              </div>
              <button type="submit">Bepul maslahat olmoqchiman</button>
              <button
                className="teleg"
                onClick={handleTelegramClick}
                type="button"
              >
                <i className="fa-brands fa-telegram"></i>Telegram orqali
                bog'lanish
              </button>
            </form>
            <div className="right-bottom">
              <p className="one">!</p>
              <p className="two">
                Hozirda o'quv markazimiz faqat Toshkent shahrida joylashgan
              </p>
            </div>
          </div>
        </div>
        <div className="foto">
          <img src={foto_absalute} alt="" />
        </div>
      </div>
    </div>
  );
}
